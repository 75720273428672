import type { FILTERS } from '~/types/ui'

interface State {
  filters: {
    [key in FILTERS]?: any
  }
}

const useFiltersStore = defineStore('filters', {
  state: (): State => ({
    filters: {},
  }),
  actions: {
    setFilter(name: FILTERS, value: any) {
      this.filters[name] = value
    },
    clearFilters() {
      this.filters = {}
    },
  },
  getters: {
    getFilter: state => (name: FILTERS) => state.filters[name],
  },
  persist: true,
})

export default useFiltersStore
